import React from 'react'
import _get from 'lodash.get'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { CenterColumn } from 'components/base/CenterColumn'
import { media, generateFontSizeCss } from 'media'
import { ExternalButtonLinkWithIcon } from 'components/ButtonLink'
import { ArrowRight } from 'components/icons/ArrowRight'

const Container = styled(CenterColumn)`
  max-width: 1300px;
`

const ContentContainer = styled.div`
  max-width: 500px;
`

const Headline = styled.h2`
  ${generateFontSizeCss(26, 46)}
  margin-bottom: 10px;
  line-height: 32px;
  width: 95%;

  ${media.desktop`
    line-height: 56px;
    margin-bottom: 15px;
  `}
`

const Description = styled.p`
  line-height: 22px;
  margin-bottom: 30px;

  ${media.desktop`
    line-height: 28px;
    margin-bottom: 60px;
  `}
`

const Blue = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`

const ButtonLink = styled(ExternalButtonLinkWithIcon)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.black};
`

export const Jobs = ({ data }) => {
  const title = _get(data, 'jobs.title', '')
  const titleText = title.substring(0, title.length - 1)
  const titleLastChar = title.substring(title.length - 1, title.length)
  const jobsDescription = _get(data, 'jobs.description', '')

  return (
    <Container>
      <ContentContainer>
        <Headline>
          {titleText}
          <Blue>
            {titleLastChar}
          </Blue>
        </Headline>
        <Description dangerouslySetInnerHTML={{ __html: jobsDescription }} />
        <ButtonLink icon={<ArrowRight />} href={'https://www.linkedin.com/company/9yagency'}>
          {_get(data, 'jobs.button_text', '')}
        </ButtonLink>
      </ContentContainer>
    </Container>
  )
}

Jobs.propTypes = {
  data: PropTypes.shape().isRequired,
}